<template>
	<div class="hangar_pages zui-bg-white zui-relative">
		<!-- 按钮区域 -->
		<hangar :isPhone='isPhone' :buttonList="buttonList" @curBtn="clickBtn" :curBtn="curBtn"
			:title="$t('hangar.name')"></hangar>
		<!-- 返回按钮 -->
		<div id="back" v-show="isPhone" @click="back"
			style="z-index:999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
		<!-- 输入框搜索区域 -->
		<div class="hangar_pages_search zui-bg-white flex_column"
			:class="language=='en' ? 'hangar_pages_search_en ': ''">
			<div class="zui-flex-1" :style="'width:' + screenWidth + 'px'">
				<div class="demo-input-suffix flex_row" style="width:100%; height:100%">
					<div class="zui-flex-row search-input">
						<div class="zui-flex-row zui-justify-end  zui-flex-1 zui-align-center">
							<div class="fontsize jinColor">{{ $t('hangar.planeBrand') }}</div>
							<el-select v-model="brand" class="elinput" @change="changeBrand"
								:placeholder="$t('hangar.all')" size="mini">
								<el-option class="jinColor" v-for="item in options1" :key="item.value"
									:label="item.label" :value="item.value"></el-option>
							</el-select>
						</div>
						<div class="zui-flex-row zui-justify-end zui-flex-1 zui-align-center">
							<div class="fontsize jinColor">{{ $t('hangar.seatNum') }}</div>
							<el-select v-model="range" @change="changeSeat" class="elinput"
								:placeholder="$t('hangar.all')" size="mini">
								<el-option class="jinColor" v-for="item in options2" :key="item.value"
									:label="item.label" :value="item.value"></el-option>
							</el-select>
						</div>
					</div>
					<div class="zui-flex-row  search-input">
						<div class="zui-flex-row zui-justify-end zui-flex-1 zui-align-center">
							<div class="fontsize jinColor">{{ $t('hangar.airRange') }}</div>
							<el-select v-model="distance" class="elinput" @change="changeDistance"
								:placeholder="$t('hangar.all')" size="mini">
								<el-option class="jinColor" v-for="item in options3" :key="item.value"
									:label="item.label" :value="item.value"></el-option>
							</el-select>
						</div>
						<div class="zui-flex-row zui-justify-end zui-flex-1 zui-align-center">
							<div class="fontsize jinColor">{{ $t('hangar.price') }}</div>
							<el-select v-model="price" class="elinput" @change="changePrice"
								:placeholder="$t('hangar.all')" size="mini">
								<el-option class="jinColor" v-for="item in options4" :key="item.value"
									:label="item.label" :value="item.value"></el-option>
							</el-select>
						</div>
					</div>
				</div>
			</div>
			<div class="zui-flex-1 flex_row zui-m-t-20">
				<el-button type="info" @click="search" style="width: 180px;border-radius: 12px;" plain class="moreBtn1"
					size="mini">{{ $t('hangar.search') }}</el-button>
			</div>
		</div>
		<!-- 推荐机型 -->
		<!-- 大屏 -->
		<div class="visible-md visible-lg zui-m-t-30">
			<div class="zui-w-100 zui-flex-row zui-justify-center">
				<div class="hangar_h1 zui-flex-column">
					<div class="zui-flex-row zui-align-center">{{ typeName1 }} <span
							class="zui-triangle-right zui-m-l-10"
							style="border-width: 16px;border-left-color: #DBB96E"></span> </div>
					<div class="zui-flex-row">
						<div class="parallelogram2"></div>
						<div class="parallelogram3"></div>
					</div>
				</div>
			</div>
		</div>
		<!-- 小屏 -->
		<div class="RecommendedModels_sm visible-sm visible-xs">
			<!-- 顶部的 -->
			<div class="hangar_tj_sm">
				<div class="kuang_sm planeType">{{ typeName1 }}</div>
			</div>
		</div>
		<!-- 卡片轮播图 -->
		<vue-seamless-scroll :data="airInfos1" :class-option="optionSwitch1" :style="cardStyle" class="seamless-warp3">
			<span slot="left-switch" class="left-arrow"></span>
			<span slot="right-switch" class="right-arrow"></span>
			<ul class="item" ref="ulBox">
				<li v-for="(item, index) in airInfos1" :key="index" class='img-list' :style="cardStyle" @click="toDetail(item)">
					<el-image class="img zui-w-100 carousel-im gzui-border-radius-small" :src="item.bannerPictures|swiperImg"
						fit="cover"></el-image>
					<div class="desc zui-text-center">{{item.title}}</div>
					<div class="image-tag1" v-if="item.sale=='Y'" style="position: absolute;top: 0px;left: 0px;">
						<!-- <img :src="item.sale=='Y'?'https://www.astroairs.com/public/images/sell_out.png':''" style="width:10%;"> -->
						<div class="tag">Sold Out</div>
					</div>
				</li>
			</ul>
		</vue-seamless-scroll>

		<div class="zui-m-t-10">
			<el-row class="hangar_row" style="display: flex;flex-wrap: wrap;">
				<el-col v-for="(item, index) in airInfos1" :key="index" class="list-item" :xs="12" :sm="8" :md="8">
					<div class="grid-content bg-purple img-list  zui-bg-white zui-overflow-hidden zui-relative"
						@click="toDetail(item)" :id="'id'+item.id.toString()">
						<el-image class="plaen-img zui-border-radius-small el-image_rest" style="width: 100%;"
							:src="item.bannerPictures|swiperImg" fit="fill"></el-image>
						<div class="text_2_line zui-p-t-10 zui-p-x-10 planeTile  zui-text-center">{{item.title}}</div>
						<div v-if="isComparsion" class="addComparsion" @click.stop="comparsion(item)">
							{{isOnArr(item.id) ?$t('hangar.off_contrast'):$t('hangar.on_contrast')}}</div>
						<div class="image-tag2" v-if="item.sale=='Y'" style="position: absolute;top: 0px;left: 0px;">
							<!-- <img :src="item.sale=='Y'?'https://www.astroairs.com/public/images/sell_out.png':''" style="width:20%;"> -->
							<div class="tag">Sold Out</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- 内容展示区 -->
		<div class="line visible-md visible-lg zui-m-t-50">
			<span class="circle circle1"></span>
			<span class="circle circle2"></span>
			<span class="circle circle3"></span>
		</div>
		<!-- 大屏 -->
		<div class="visible-md visible-lg">
			<div class="zui-w-100 zui-flex zui-center-center">
				<div class="hangar_h1 zui-w-100 zui-flex-row zui-align-center">{{ typeName3 }} <span
						class="zui-triangle-right zui-m-l-10"
						style="border-width: 16px;border-left-color: #DBB96E"></span> </div>
			</div>
		</div>
		<!-- 小屏 -->
		<div class="RecommendedModels_sm visible-sm visible-xs">
			<!-- 顶部的 -->
			<div class="hangar_tj_sm">
				<div class="kuang_sm planeType">{{ typeName3 }}</div>
			</div>
		</div>
		<!-- 两行新品 -->
		<div>
			<el-row class="hangar_row">
				<el-col v-for="(item, index) in airInfos3" :key="index" class="list-item" :xs="12" :sm="8" :md="8"
					:xl="8" :lg="8">
					<div class="grid-content bg-purple img-list zui-bg-white zui-overflow-hidden zui-relative"
						@click="toDetail(item)" :id="'id'+item.id.toString()">
						<el-image class="plane-img zui-border-radius-small el-image_rest" style="width: 100%;"
							:src="item.bannerPictures|swiperImg" fit="fill"></el-image>
						<div class="text_2_line zui-p-t-10 zui-p-x-10 planeTile  zui-text-center">
							{{item.title}}</div>
						<div v-if="isComparsion" class="addComparsion" @click.stop="comparsion(item)">
							{{isOnArr(item.id) ?$t('hangar.off_contrast'):$t('hangar.on_contrast')}}</div>
						<div class="image-tag2" v-if="item.sale=='Y'" style="position: absolute;top: 0px;left: 0px;">
							<!-- <img :src="item.sale=='Y'?'https://www.astroairs.com/public/images/sell_out.png':''" style="width:20%;"> -->
							<div class="tag">Sold Out</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<div class="moreBox" style="margin-top:20px">
				<div class="more-load" v-if="isMoreLoad" @click="scrollLoadMore2">
					<span v-if="loadingImg">{{$t('common.moreText')[1]}}</span>
					<span v-else>{{$t('common.moreText')[0]}}</span>
				</div>
				<div class="more-load" v-if="loadLastText" id="loadMore">{{$t('common.moreText')[2]}}</div>
			</div>
		</div>
		<div class="zanwei"></div>
		<!-- 对比图标 -->
		<div :class="[isComparsion?'current comparsion':'comparsion']" @click="goToComparsion">
			<div :class="language =='en' ? 'comparsion_en' : ''">
				{{isComparsion?$t('hangar.contrast'):$t('hangar.open_contrast')}}</div><span
				v-if="comparsionNum > 0">{{comparsionNum}}</span>
		</div>
		<!-- 填表 -->
		<div class="bottom_input">
			<el-row class="flex_column" style="width: 100%;height: 100%;">
				<el-col :xs="20" :sm="16" :md="12" :lg="12" :xl="8" class="flex_column zui-h-100">
					<div class="bottom_input_top">
						<p>{{ $t('hangar.contact_title') }}</p>
						<p>{{ $t('hangar.contact_desc') }}</p>
					</div>
					<div class="flex_row zui-w-100">
						<el-input v-model="formData.contactName" :placeholder="$t('hangar.contactName')"
							class="elel_input" size="mini"></el-input>
						<el-input v-model="formData.contactPhone" :placeholder="$t('hangar.contactPhone')"
							class="elel_input" size="mini"></el-input>
					</div>
					<div class="flex_row zui-w-100">
						<el-input v-model="formData.email" :placeholder="$t('hangar.email')" class="elel_input"
							size="mini"></el-input>
					</div>
					<div class="flex_row zui-w-100">
						<el-input v-model="formData.planeType" :placeholder="$t('hangar.planeType')" class="elel_input"
							size="mini"></el-input>
						<el-input v-model="formData.seatNum" :placeholder="$t('hangar.seatNum')" class="elel_input"
							size="mini"></el-input>
					</div>
					<div class="flex_row zui-w-100">
						<el-input v-model="formData.airRange" :placeholder="$t('hangar.airRange')" class="elel_input"
							size="mini"></el-input>
						<el-input v-model="formData.price" :placeholder="$t('hangar.price')" class="elel_input"
							size="mini"></el-input>
					</div>
					<div class="flex_row zui-w-100 zui-m-t-20">
						<el-button type="warning" @click="planeMethod()" plain size="mini" class="contact">
							{{ $t('hangar.contact_us') }}</el-button>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>
	import $config from "../api/config.js"
	import hangar from '../components/hangarCom';

	import {
		getAirPlaneType,
		customerService,
		getHangars,
		getBrandAndType,
		getAirInfo
	} from '../api/api';
	import vueSeamlessScroll from 'vue-seamless-scroll';
	import {
		mapState,
	} from 'vuex';
	export default {
		data() {
			return {
				currentTitle: '',
				isPhone: false,
				liW: 510,
				showScroll: true,
				comparsionIds: [],
				isComparsion: false,
				buttonList: [],
				curBtn: -1,
				// input1: '',
				// input2: '',
				screenWidth: '',
				bannerHeight: '',
				centerHeight: '',
				cardType: 'card',
				listData: [],
				// planeSellType: [], //销售类型
				// planeSellTypeInfo: [], //飞机
				typeName1: '',
				typeName2: '',
				typeName3: '',
				airInfos1: [],
				airInfos2: {},
				airInfos3: {},
				queryData: {
					planeBrand: '',
					// planeType: '',
					steatsNum_begin: '',
					steatsNum_end: '',
					maxRange_begin: '',
					maxRange_end: '',
					price_end: '',
					price_begin: ''
				},
				brand: '',
				distance: '',
				range: '',
				price: '',
				options1: [],
				options2: [{
						value: 0,
						label: '全部'
					},
					{
						value: 1,
						label: '12座以下'
					},
					{
						value: 2,
						label: '12-16座'
					},
					{
						value: 3,
						label: '16座以上'
					}
				],
				options3: [{
						value: 0,
						label: '全部'
					},
					{
						value: 1,
						label: '5000千米以下'
					},
					{
						value: 2,
						label: '5000-8000千米'
					},
					{
						value: 3,
						label: '8000-11000千米'
					},
					{
						value: 4,
						label: '11000千米以上'
					}
				],
				options4: [{
						value: 0,
						label: '全部'
					},
					{
						value: 1,
						label: '$ 1000万以下'
					},
					{
						value: 2,
						label: '$ 1000-5000万'
					},
					{
						value: 3,
						label: '$ 5000万以上'
					}
				],
				formData: {
					planeName: '',
					contactName: '',
					contactPhone: '',
					airRange: '',
					seatNum: '',
					email: '',
					planeAge: '',
					price: '',
					planeImg: ''
				},
				searchQuery: {
					brand: 0,
					type: -1,
					distance: 0,
					price: 0,
					seat: 0
				},
				comparsionIdArr: [],
				comparsionNum: 0,
				definePageSize: 3,
				definePageNum: 4,

			};
		},
		computed: {
			...mapState(["language"]),
			optionSwitch1() {
				return {
					// limitMoveNum: 5,
					switchSingleStep: this.liW,
					direction: 2,
					switchOffset: 10,
					hoverStop: true,
					openTouch: false,
					openWatch: true,
					autoPlay: false,
					isSingleRemUnit: true,
					navigation: true,
				};
			},
			cardStyle() {
				let node = {
					width: this.screenWidth * 0.53333 + 'px'
				}
				return node
			}
		},
		filters: {
			swiperImg: function(value) {
				// console.log(value);
				if ((value.search("http") != -1) && (value.search("https") != -1)) {
					return value
				} else {
					return $config.apiUrl + '/' + value
				}
			}
		},
		created: function() {
			if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
				this.cardType = 'a';
				this.isPhone = true
			} else {
				this.cardType = 'a';
				// this.cardType = 'card';
				this.isPhone = false
			}
			this.hangars();
			this.getData();

			// let params = this.$route;
			// console.log(params)

	
		},
		watch: {
			language() {
				this.setEn()
			},
			$route(from) {
				// 监听路由变化, 实现类似 小程序的 onShow 事件
				if (from.params.id) {
					this.curBtn = from.params.id - 1;
				} else {
					this.curBtn = 0;
				}
				//console.log(from.params)
			},
			comparsionIdArr(newV) {
				this.comparsionNum = newV.length
				if (this.comparsionNum == 0) {
					this.isComparsion = false
				}
			}
		},
		methods: {
			setEn() {
				this.options2 = [{
					value: 0,
					label: this.$t('hangar.all')
				}, {
					value: 1,
					label: this.$t('hangar.mesg1')
				}, {
					value: 2,
					label: this.$t('hangar.mesg2')
				}, {
					value: 3,
					label: this.$t('hangar.mesg3')
				}]
				this.options3 = [{
					value: 0,
					label: this.$t('hangar.all')
				}, {
					value: 1,
					label: this.$t('hangar.mesg4')
				}, {
					value: 2,
					label: this.$t('hangar.mesg5')
				}, {
					value: 3,
					label: this.$t('hangar.mesg6')
				}, {
					value: 4,
					label: this.$t('hangar.mesg7')
				}]
				this.options4 = [{
					value: 0,
					label: this.$t('hangar.all')
				}, {
					value: 1,
					label: this.$t('hangar.mesg8')
				}, {
					value: 2,
					label: this.$t('hangar.mesg9')
				}, {
					value: 3,
					label: this.$t('hangar.mesg10')
				}]
			},
			isOnArr(id = 0) {
				return this.comparsionIdArr.includes(id);
			},
			comparsion(id = {}) {
				// console.log(this.comparsionIdArr);
				if (this.isOnArr(id.id)) {
					let index = this.comparsionIdArr.indexOf(id.id);
					if (index != -1) {
						this.comparsionIdArr.splice(index, 1)
					}
					let index1 = this.comparsionIds.indexOf(id);
					if (index != -1) {
						this.comparsionIds.splice(index1, 1)
					}
				} else {
					this.comparsionIds.push(id)
					this.comparsionIdArr.push(id.id)
				}
				// console.log(this.comparsionIds);
			},
			goToComparsion() {
				if (this.isComparsion == false) {
					this.isComparsion = true;
					return;
				}
				if (this.isComparsion == true && this.comparsionNum < 2 && this.comparsionNum > 0) {
					this.$message({
						message: this.$t('hangar.mesg'),
						showClose: true,
						type: 'error',
						customClass: 'hahaha',
						duration: 2000,
						offset: 20
					});
					return;
				}
				if (this.comparsionNum >= 2) {
					this.$router.push({
						name: 'comparison',
						params: {
							ids: JSON.stringify(this.comparsionIds)
						}
					});
				} else {
					this.isComparsion = false;
					this.comparsionIds = [];
					this.comparsionIdArr = [];
				}

			},
			search() {
				this.$router.push({
					name: 'search',
					params: {
						search: this.searchQuery
					}
				});
			},
			getData() {
				//获取品牌
				getBrandAndType().then(res => {
					// console.log(res);
					if (res.code == 200) {
						let data = res.result.brandVoList;
						let types = [{
							value: 0,
							label: this.$t('hangar.all')
						}];
						let brandNoArr = [];
						this.$zui.arrayEach(data, (item, index) => {
							let temp = {};
							temp.label = item.brandName;
							temp.value = index + 1;
							let temp2 = [];
							if (item.planeTypeVoList.length > 0) {
								this.$zui.arrayEach(item.planeTypeVoList, v => {
									let temp1 = {};
									temp1.label = v.typeName;
									temp1.value = v.typeId;
									temp2.push(temp1);
								});
							}
							types.push(temp);
							brandNoArr.push(temp2);
						});
						this.options1 = types;
						this.brandNoArr = brandNoArr;
						// console.log(this.options1);

					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}

				});
				//获取飞机类型
				getAirPlaneType().then(res => {
					// console.log(res);
					if (res.code == 200) {
						let data = res.result.records;

						this.$zui.arrayEach(data, (item, index) => {
							let temp = {};
							temp.name = item.typeName;
							temp.id = index;
							temp.typeId = item.id;
							this.buttonList.push(temp);
							if (this.searchQuery.type == index) {
								this.queryData.planeType = item.id;
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});

				getAirInfo({
					pageSize: 9,
					pageNo: 1,
				}).then(res => {
					// console.log(res,123)
					this.airInfos3 = res.result.records;
					//console.log(res.result.total)
					this.typeName3 = "全部机型";
				})

			},
			//加载更加
			scrollLoadMore2() {
				// 防止多次加载
				if (this.loadingImg) {
					return;
				}
				//开启加载动画
				this.loadingImg = true;
				//调取 API
				this.getAirInfoList();
			},
			async getAirInfoList() {

				await getAirInfo({
					pageSize: this.definePageSize,
					pageNo: this.definePageNum,
				}).then(res => {
					if (res.code == 200) {
						//分页 - S
						//判断是否是第一页
						this.totals = res.result.total; //总数据 

						if (this.definePageNum >= 2) {
							//追加数据
							this.airInfos3 = this.airInfos3.concat(res.result.records)
							//console.log(this.airInfos3)
						}

						if (this.totals - this.definePageNum * this.definePageSize > 0) {
							this.isMoreLoad = true; //显示 加载更多 按钮
							this.loadLastText = false; //显示 没有更多数据了 按钮
						} else {
							this.isMoreLoad = false; //不再显示 加载更多 按钮
							this.loadLastText = true; //显示 没有更多数据了 按钮
						}
						//分页++
						this.definePageNum + 1;
						// 加载完成后停止加载动画
						this.loadingImg = false;
						//分页
		
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			hangars() {

				getHangars().then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.typeName1 = res.result.records[0].typeName;
						this.typeName2 = res.result.records[1].typeName;
						// this.typeName3 = res.result.records[2].typeName;
						this.airInfos1 = res.result.records[0].airInfos;
						this.airInfos2 = res.result.records[1].airInfos;
						// this.airInfos3 = res.result.records[2].airInfos;
						// console.log(res.result.records)
			
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
					}
				});
			},
			clickBtn(id) {
				this.curBtn = id;
				this.searchQuery.type = id;
				this.searchQuery.flag = 'params'
				//console.log(this.searchQuery)
				this.$router.push({
					name: 'search',
					params: {
						search: this.searchQuery
					}
				});
				// this.$router.params.id=1
			},
			setSize: function() {
				let _this = this;
				// 通过浏览器宽度(图片宽度)计算高度
				if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
					this.bannerHeight = (800 / 1920) * this.screenWidth * 0.9;
				} else {
					this.bannerHeight = (750 / 1920) * this.screenWidth * 0.8;
					// console.log(this.bannerHeight);
				}
				_this.showScroll = false;
				// this.$nextTick(()=>{
				// })
				_this.showScroll = true;
				this.centerHeight = (1080 / this.screenWidth) * this.screenWidth;
			},
			// 跳转详情页
			toDetail(item) {

				localStorage.setItem('posi', item.id)
				this.$router.push({
					name: 'airplaneDetail',
					params: {
						planeInfo: item
					}
				});

			},
			resetForm: function() {
				//清空表单
				for (let k in this.formData) {
					this.formData[k] = '';
				}
			},
			planeMethod: function(type = 'airFindInfoAdd') {
				//定义表单规则
				var rule = [{
						name: 'contactName',
						checkType: 'notnull',
						checkRule: '',
						errorMsg: this.$t('hangar.contactName')
					},
					{
						name: 'contactPhone',
						checkType: 'phoneno',
						checkRule: '6,',
						errorMsg: this.$t('hangar.contactPhone')
					},
					{
						name: 'email',
						checkType: 'email',
						checkRule: '',
						errorMsg: this.$t('hangar.email')
					},
					{
						name: 'planeName',
						checkType: 'notnull',
						checkRule: '',
						errorMsg: this.$t('hangar.planeType')
					},
					{
						name: 'seatNum',
						checkType: 'notnull',
						checkRule: '',
						errorMsg: this.$t('hangar.seatNum')
					},
					{
						name: 'airRange',
						checkType: 'notnull',
						checkRule: '',
						errorMsg: this.$t('hangar.airRange')
					},
					{
						name: 'price',
						checkType: 'notnull',
						checkRule: '',
						errorMsg: this.$t('hangar.price')
					}
				];
				if (!this.$zuiChecker.check(this.formData, rule)) {
					this.$message({
						message: this.$zuiChecker.error,
						showClose: true,
						type: 'error',
						duration: 2000,
						customClass: 'hahaha',
						offset: 20
					});
					return;
				}

				// console.log(type);
				customerService[type](this.formData).then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.$message({
							message: this.$t('common.submitSuccess'),
							showClose: true,
							type: 'success',
							customClass: 'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								this.resetForm();
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			changeSeat(e) {
				this.searchQuery.seat = e;
			},
			changeDistance(e) {
				this.searchQuery.distance = e;
			},
			changePrice(e) {
				this.searchQuery.price = e;
			},
			changeBrand(e) {
				this.searchQuery.brand = e;
			},
			to_scroll() {
				let posi = 'id' + localStorage.getItem('posi')
				if (posi) {
					var dom = document.getElementById(posi)
					//console.log('获取到dom了',dom)
					if (dom) {
						dom.scrollIntoView({
							behavior: "smooth", // 平滑过渡
							block: "center" // 滚到屏幕中间
						})
					}

				}
				localStorage.setItem('posi', '')
				//清楚 posi的值，以防再次载入页面时发生滚动
			}
		},
			updated() {
				setTimeout(() => {
				this.to_scroll()
				},600)
			},
			mounted() {

			setTimeout(() => {
			this.to_scroll()
			},600)
	


			// 首次加载时设置语言
			this.setEn()
			// 首次加载时,需要调用一次
			this.screenWidth = window.innerWidth;
			// console.log(this.screenWidth);
			// 窗口大小发生改变时,调用一次
			this.setSize();
			this.liW = this.screenWidth * 0.53333;
			// 窗口大小发生改变时,调用一次
			window.onresize = () => {
				this.screenWidth = window.innerWidth;
				this.setSize();
			};
			//window.scroll(0,0);
			window.scrollY = 0
			window.window.pageYOffset = 0
			// console.log(window.scrollY,window)
			this.scrollLoadMoreEvnHangar();

		},
		components: {
			hangar,
			vueSeamlessScroll
		}
	};
</script>
<style lang="scss" scoped>
	@import '../assets/scss/config.scss';
	@import '../assets/scss/mixin.scss';

.img-list{
		position: relative;
	}
	.image-tag1{
		.tag{
			padding:10px 40px;
			font-size: 18px;
		}
	}
	.image-tag2{
		position: absolute;
		top: 10px;
		left: 10px;
	}
	.tag{
		padding:5px 20px;
		color: white;
		background-color: #000;
	}
	.seamless-warp3 {
		overflow: hidden;
		margin: 0 auto;

		.left-arrow,
		.right-arrow {
			position: relative;
			display: inline-block;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background-color: white;
			cursor: pointer;
			z-index: 100;
			box-shadow: 0 0 8px 2px #ececec;

			&:hover {
				background-color: white;
			}

			&::before {
				position: absolute;
				content: '';
				width: 16px;
				height: 16px;
				top: 12px;
				left: 15px;
				border: 2px solid #dbb96e;
			}
		}

		.left-arrow::before {
			border-right: 0;
			border-bottom: 0;
			transform: rotate(-45deg);
		}

		.right-arrow::before {
			border-left: 0;
			border-top: 0;
			left: 9px;
			transform: rotate(-45deg);
		}

		ul.item {
			display: flex;
			justify-content: flex-start;
			flex-wrap: nowrap;
			margin: 0;
			padding: 0;
			width: 100%;
			list-style: none;

			li {
				display: flex;
				height: 100%;
				box-sizing: border-box;
				flex-direction: column;

				/deep/ .img {
					background-color: #999;
					padding-top: 56%;
					width: 100%;
					position: relative;

					img {
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
					}
				}

				.desc {
					// height: 60px;
					font-size: 12px;
					padding: 10px 10px 0;
				}
			}
		}
	}

	.el-carousel__mask {
		background-color: transparent !important;
	}

	// flex_column
	.hangar_pages {
		width: 100%;
		overflow: hidden;

		.hangar_pages_top {
			width: 100%;
			height: 138px;
			background: url('../assets/image/15.jpg') no-repeat center;
			background-size: 100% 100%;

			div {
				color: $colorJ;
				font-size: $fontD;
			}
		}
	}

	.hangar_pages_search_en {
		height: 180px !important;
	}

	.hangar_pages_search {
		width: 100%;
		height: 138px;
		background-color: white;
		box-shadow: 0 15px 20px 4px #d1d1d1;

		.elinput {
			width: 125px;
			height: 23px;
			margin-left: 10px;
		}

		.demo-input-suffix {
			margin-top: 20px;

			.fontsize {
				font-size: 15px;
				margin-top: 3px;
				// width:45px;

				text-align: right;
			}
		}

		.bot_btn {
			width: 200px;
			background-color: #000000;
			color: $colorG;
		}

		/deep/ .el-input__inner {
			background-color: transparent !important;
			border: 1px solid #dbb96e !important;
			color: #dbb96e !important;
		}

		/deep/ .el-select .el-input .el-select__caret {
			color: #dbb96e !important;
		}

		.el-button--info.is-plain:focus,
		.el-button--info.is-plain:hover {
			background-color: white;
			border: 1px solid #dbb96e !important;
			color: #dbb96e !important;
		}
	}

	.bottom_text {
		text-align: right;
	}

	// 新品机型
	.newModels {
		position: relative;
		height: 370px;

		.conter {
			position: inherit;
			width: 80%;
			font-size: 10px;
			top: 104px;
			font-size: 10px;
			left: 53%;
			transform: translate(-50%, 0%);

			img {
				width: 100%;
				height: 100%;
			}

			.cont_mid {
				width: 100%;
			}

			.cont_bottom {
				width: 100%;
				text-align: right;
			}
		}
	}

	// 推荐机型
	.RecommendedModels {
		width: 100%;
		// height: 20px;
		position: relative;
		margin-bottom: 50px;
	}

	.heng {
		width: 900px;
		background-color: #000000;
		height: 1px;
		margin: 0 auto;
		position: absolute;
		top: 32px;
		left: 50%;
		transform: translate(-50%, -25%);
	}

	.kuang {
		width: 133px;
		height: 23px;
		line-height: 0.32rem;
		text-align: center;
		background-color: #000000;
		color: $colorG;
		position: absolute;
		top: 25px;
		left: 50%;
		transform: translate(-50%, -25%);
	}

	.hangar_tj {
		width: 100%;
		text-align: center;
		margin: 20px auto;
	}

	.hangar_h1 {
		width: 80%;
		padding: 20px 0;
		font-size: 25px;

	}

	.btnColor {
		width: 147px;
		height: 32px;
	}

	.act {
		background-color: $colorJ;
		color: $colorG;
	}

	.el-carousel-item {
		img {
			width: 100%;
			height: 100%;
		}
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
	}

	.comparsion_en {
		font-size: 10px !important;
	}

	.comparsion {
		position: fixed;
		right: 7%;
		bottom: 20%;
		background-color: white;

		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		width: 60px;
		height: 60px;
		border-radius: 60px;
		box-shadow: 0 0 4px 5px #cecece;
		cursor: pointer;
		z-index: 1999;

		&:hover {
			box-shadow: 0 0 4px 5px #DBB96E;
			color: #DBB96E;
		}

		&.current {
			box-shadow: 0 0 4px 5px #DBB96E;
			color: #DBB96E;

		}

		span {
			padding: 4px 8px;
			font-size: 12px;
			background-color: red;
			color: white;
			border-radius: 18px;
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}

	.addComparsion {
		padding: 3px 5px;
		font-size: 10px;
		border-radius: 3px;
		border: 1px solid #DBB96E;
		color: #DBB96E;
		position: absolute;
		background-color: white;
		cursor: pointer;
		top: 5px;
		right: 5px;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
		// max-height: 300px;
		border-radius: 4px;
		// padding: 0 20px;
		margin: 0 10px;
		box-sizing: border-box;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}

	.zanwei {
		width: 100%;
		height: 30px;
		background-color: #00000000;
	}

	.hangar_row {
		width: 80%;
		font-size: 12px;
		margin: auto;
	}

	.el-image_rest {
		height: calc(80vw / 4.5) !important;
	}

	.bottom_input {
		background-image: url('../assets/image/18.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		margin: 10px auto 0;
		height: 450px;
		text-align: center;
		font-size: 12px;

		.bottom_input_top {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 30px;
			margin-bottom: 60px;
			font-weight: bold;

			p {
				font-size: 21px;
				font-family: MicrosoftYaHei;
			}
		}
	}

	.elel_input {
		width: 100%;
		height: 19px;
		margin: 10px 20px;
	}

	.search-input {
		width: 520px;
	}

	// .flex_row {
	// 	flex-wrap: wrap;
	// }

	.list-item {
		margin-bottom: 40px;
		cursor: pointer;
	}

	.RecommendedModels_sm {
		margin-top: 20px;
	}

	.hangar_tj_sm {
		background-image: url('../assets/image/17.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 30px;
		position: relative;
		margin-bottom: 25px;
	}

	.kuang_sm {
		font-size: 14px;
		color: #daba6d;
		position: absolute;
		left: 6%;
		bottom: 5%;
	}

	.kuang_sm::after {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		right: -20px;
		bottom: 4px;
		border-top: solid 6px transparent;
		border-right: solid 6px #daba6d;
		border-left: solid 6px transparent;
		/* 黑色大三角形 */
		border-bottom: solid 6px #daba6d;
	}

	.planeType {
		font-size: 18px;
		font-family: MicrosoftYaHeiLight;
	}

	.planeTile {
		font-size: 12px;
		font-family: MicrosoftYaHeiLight;
		height: 60px;
	}

	.planePrice {
		font-size: 14px;
		font-family: MicrosoftYaHeiLight;
	}

	.moreBtn1 {
		text-align: center;
		padding: 5px 10px;
		box-sizing: border-box;
		color: #dab96b;
		font-size: 14px;
		border-radius: 2px;
		border: 1px solid #dab96b;
		background-color: transparent;
	}

	.parallelogram2 {
		width: 150px;
		height: 20px;
		-webkit-transform: skew(60deg);
		-moz-transform: skew(60deg);
		-o-transform: skew(60deg);
		-ms-transform: skew(60deg);
		transform: skew(60deg);
		border-top: 1px solid #DBB956;
		border-right: 2px solid #e3be59;
		overflow: hidden;

	}

	.parallelogram3 {
		flex: 1;
		border-bottom: 1px solid #DBB956;
		overflow: hidden;
		margin-left: 15px;
	}

	//飞机宽高
	/deep/ .plane-img {
		padding-top: 60%;
		width: 100%;
		position: relative;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
		}
	}

	//幻灯片图片高度
	/deep/ .carousel-img {
		padding-top: 35%;
		width: 100%;
		position: relative;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
		}
	}

	@media screen and (max-width: 1920px) {
		.el-image_rest {
			// height: 230px !important;
			height: calc(80vw / 4.5) !important;
		}
	}

	@media screen and (max-width: 1420px) {
		.el-image_rest {
			// height: 200px !important;
			height: calc(80vw / 5) !important;
		}
	}

	// 手机适配
	@media screen and (max-width: 960px) {
		.image-tag1{
			.tag{
				padding:5px 20px;
				font-size: 16px;
			}
		}
		
		.el-image_rest {
			// height: 130px !important;
			height: calc(100vw / 4) !important;
		}

		/deep/ .left-arrow,
			{
			width: 30px !important;
			height: 30px !important;

			&::before {
				width: 12px !important;
				height: 12px !important;
				top: 8px !important;
				left: 13px !important;
			}
		}

		/deep/ .right-arrow {
			width: 30px !important;
			height: 30px !important;

			&::before {
				width: 12px !important;
				height: 12px !important;
				top: 8px !important;
				left: 8px !important;
			}
		}

		//幻灯片图片高度
		/deep/ .carousel-img {
			padding-top: 55%;
			width: 100%;
			position: relative;

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
			}
		}

		.search-input {
			// width: 340px;
		}

		.list-item {
			margin-bottom: 10px;
		}

		// 占位DIV
		.zanwei {
			width: 100%;
			height: 20px;
			background-color: #00000000;
		}

		//
		.hangar_row {
			width: 100%;
			margin: auto;
		}

		.grid-content {
			margin: 0 10px;
		}

		// 新品机型底下文字
		.bottom_text {}

		.hangar_pages_search {
			.flex_row {
				flex-wrap: wrap;
			}

			width: 100%;
			height: 138px;
			background-color: $colorE;

			.elinput {
				width: 125px;
				height: 23px;
				margin-left: 10px;
			}

			.demo-input-suffix {
				margin-top: 20px;

				.fontsize {
					font-size: 10px;
				}
			}

			.bot_btn {
				background-color: #000000;
				color: $colorG;
			}
		}


		.planeTile {

			height: 50px;
		}

		.planePrice {
			font-size: 12px;
			padding-top: 0;
			font-family: MicrosoftYaHeiLight;
		}
	}

	.contact {
		background: #d9b86b;
		border: #d9b86b;
		color: white;
	}
</style>
